import React from "react";

import BonusPoint from "./../bonuspoint/bonuspoint";
import TwoBox from "./../twopoint/twopoint";
import HomeBanner from "./../homebanner/homebanner";
import RewardsWrapper from "./../rewardswrapper/rewardswrapper";
import homeData from "./../../data/home.json";
import dashboardData from "./../../data/dashboard.json";
import RewardsDashboard from "../dashboard/dashboard";
import GetRewarded from "../getRewarded/getRewarded";
import RedeemRewards from "../redeemRewards/redeemRewards";
import { useStoreState } from "easy-peasy";
import RewardsCarousel from "../rewardsCarousel/rewardsCarousel";
import Disclosure from "../disclosure/disclosure";

const Home = (props) => {
  document.title = props.title;
  const getUserValue = useStoreState((state) => state.user.userInfo); 

  return (
    <>
      {getUserValue ? ( 
        // Show this if user is authenticated
        <>
          <RewardsDashboard
            dashboardData={dashboardData.dashboardData}
            rewardsBalance={52500}
            pointsEarned={2600}
          />
          <RewardsCarousel />
          <RedeemRewards />
          <GetRewarded />
          <Disclosure/>
        </>
      ) : (
        // Show this if user is not authenticated
        <>
          <HomeBanner homeBanner={homeData.homeBanner} />
          <TwoBox boxData={homeData.boxData} />
          <RewardsWrapper rewards={homeData.rewards} />
          <Disclosure/>
        </>
      )}
    </>
  );
};

export default Home;
