import React from "react";

import { Tab, Tabs, Button } from "react-bootstrap";
import { useStoreState } from "easy-peasy";
import { toSentenceCase } from "../../utils/stringUtils"; 
import "./rewardsTabs.css";

const RewardsTabs = ({ redeemRewards }) => {
  const getUserValue = useStoreState((state) => state.user.userInfo);
  
  return (
    <div className="pnc-rewards-tabs container">
      <div className="row">
        <h2 className="pnc-rewards-tabs-title" id="pnc-redeem-rewards-section-heading">
          {redeemRewards[0].headline}
        </h2>
      </div>
      <div className="row pnc-rewards-row">
        <div className="col-md-12">
          <Tabs defaultActiveKey="travel" id="justify-tab-rewards" className="mb-3" justify>
            {redeemRewards
              ? redeemRewards.map((redeemReward, index) => {
                  return (
                    <Tab key={index} eventKey={redeemReward.eventKey} title={redeemReward.title}>
                      <div className="pnc-rewards-tab-content">
                        <div className="row">
                          <div className="col-md-5">
                            <div className="pnc-rewards-tab-content">
                              {redeemReward.eventKey === "travel" ? (
                                getUserValue.JsonExternalData.CurrentBalance > 5000 ? (
                                  <p className="pnc-rewards-tab-desc" tabIndex="0">
                                  {`${(toSentenceCase(getUserValue.FirstName).trim())}, get up to $${(Math.round(getUserValue.JsonExternalData.CurrentBalance)*0.01).toFixed(2)} in travel rewards by redeeming ${
                                    getUserValue.JsonExternalData.CurrentBalance ? Math.round(getUserValue.JsonExternalData.CurrentBalance).toLocaleString() : ""
                                  } rewards points`}
                                </p>
                                ) : (
                                  <p className="pnc-rewards-tab-desc" tabIndex="0">
                                    {`${(toSentenceCase(getUserValue.FirstName).trim())}, see how you can unlock travel rewards by earning rewards points`}
                                  </p>
                                )
                              ) : (
                                <p className="pnc-rewards-tab-desc" tabIndex="0">
                                  {redeemReward.desc}
                                </p>
                              )}
                              <Button href={global.catalogUrl+redeemReward.btnUrl} className="pnc-rewards-tab-btn" aria-label={`${redeemReward.btnText}`}>
                                {redeemReward.btnText}
                              </Button>
                              {redeemReward.btnText2 ? (
                                <Button href={global.catalogUrl+redeemReward.btnUrl2} className="pnc-rewards-tab-btn-2" aria-label={`${redeemReward.btnText2}`}>
                                  {redeemReward.btnText2}
                                </Button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-md-7">
                            <img className="pnc-rewards-tab-img" src={redeemReward.src} alt={redeemReward.alt} tabIndex="0" aria-label={redeemReward.alt} />
                          </div>
                        </div>
                      </div>
                    </Tab>
                  );
                })
              : false}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default RewardsTabs;
